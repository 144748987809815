import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { BASE_URL } from "../../config";

const Addstory = () => {
  const [image, setImage] = useState(null);
  const [storyTitle, setStoryTitle] = useState("");
  const [storyDescription, setStoryDescription] = useState("");
  const [language, setLanguage] = useState("");
  const [status, setStatus] = useState("");
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [stories, setStories] = useState([]);

  const Navigate = useNavigate();
  const location = useLocation();
  const { item } = location.state || {};

  useEffect(() => {
    if (item?.postId) {
      StoryApicallmethod(item.postId);
    }
  }, [item]);

  const StoryApicallmethod = async (postId) => {
    try {
      const response = await fetch(`${BASE_URL}/mm/visual/${postId}`);
      const data = await response.json();
      setStories(data.data);
      // setAlert({ show: false, message: "Success", type: "" });
    } catch (error) {
      console.error("Error fetching stories:", error);
      setAlert({
        show: true,
        message: "Error fetching stories. Please try again.",
        type: "danger",
      });
    }
  };

  const handleImageChange = (event) => {
    setImage(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    const data = {
      title: storyTitle,
      status,
      description: storyDescription,
      languageId: language,
      postId: item?.postId,
    };

    formData.append("data", JSON.stringify(data));
    formData.append("image", image);

    try {
      const response = await fetch(`${BASE_URL}/mm/saveOrUpdatevisual`, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      setAlert({ show: true, message: result.message, type: "success" });

      setLanguage("");
      setStatus("");
      setStoryDescription("");
      setStoryTitle("");
      setTimeout(() => {
        setAlert({ show: false, message: "", type: "" });
        Navigate("/stories");
        StoryApicallmethod(item?.postId); // Use item?.postId here
      }, 1000);
    } catch (error) {
      console.error("Error:", error);
      setAlert({
        show: true,
        message: "Error adding post. Please try again.",
        type: "danger",
      });
    }
  };

  const gotoeditstory = (story) => {
    Navigate("/editstory", { state: { item: story } });
  };

  const gotodeletestory = (story) => {
    const apiUrl = `${BASE_URL}/mm/delete?type=visualstory&id=${story.imageId}`;
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          setAlert({ show: true, message: "Deleted Success", type: "success" });

          setTimeout(() => {
            setAlert({ show: false, message: "", type: "" });
            StoryApicallmethod(item?.postId);
            Navigate("/stories");
          }, 1000);
        } else {
          // Handle unsuccessful response
          return response.json().then((errorData) => {
            setAlert({ show: false, message: errorData.message, type: "" });

            Navigate("/addstory");
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setAlert({ show: false, message: error, type: "" });

        Navigate("/addstory");
      });
  };

  const handleAlertClose = () => {
    setAlert({ show: false, message: "", type: "" });
    Navigate("/stories");
  };

  const postredirect = () => {
    Navigate("/stories");
  };

  return (
    <div className="container-fluid p-auto m-auto">
      <div className="d-flex justify-content-between mt-3">
        <h4 className="fs-4">Add Story</h4>
        <button
          className="btn btn-outline-warning text-dark shadow"
          onClick={postredirect}>
          {" "}
          Stories
        </button>
      </div>
      {alert.show && (
        <div
          className={`alert alert-${alert.type} alert-dismissible w-50 fade show m-auto p-auto`}
          role="alert">
          {alert.message}
          <button
            type="button"
            className="btn-close"
            onClick={handleAlertClose}></button>
        </div>
      )}
      <form onSubmit={handleSubmit} className="card shadow mt-3">
        <div className="row m-2">
          <div className="col-12">
            <h6 className="text-danger">{item?.postTitle}</h6>
          </div>
        </div>
        <div className="row m-2">
          <div className="col-12 col-md-6">
            <label className="form-label">Story Title *</label>
            <input
              type="text"
              className="form-control"
              value={storyTitle}
              onChange={(e) => setStoryTitle(e.target.value)}
              required
            />
          </div>
          <div className="col-12 col-md-6">
            <label className="form-label">Select Language</label>
            <select
              className="form-select"
              value={language}
              onChange={(e) => setLanguage(e.target.value)}>
              <option value="">Select Language</option>
              <option value={1}>English</option>
              <option value={2}>Hindi</option>
              <option value={3}>Telugu</option>
            </select>
          </div>
        </div>
        <div className="row m-2">
          <div className="col-12 col-md-6">
            <label className="form-label">Status *</label>
            <select
              className="form-select"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              required>
              <option value={0}>--select--</option>
              <option value={1}>Publish</option>
              <option value={2}>Pending</option>
            </select>
          </div>
          <div className="col-12 col-md-6">
            <label className="form-label">Choose Image *</label>
            <input
              type="file"
              className="form-control"
              accept="image/*"
              onChange={handleImageChange}
              required
            />
            <small className="text-danger">
              Please Upload 720 X 1280 in Pixels Only
            </small>
          </div>
        </div>
        <div className="row m-2">
          <div className="col-6">
            <label className="form-label">Story Description</label>
            <textarea
              className="form-control"
              rows="3"
              value={storyDescription}
              onChange={(e) => setStoryDescription(e.target.value)}
            />
          </div>
        </div>
        <div className="d-flex justify-content-center m-3">
          <button type="submit" className="btn btn-primary mx-1">
            Save
          </button>
          <button
            type="button"
            className="btn btn-secondary mx-1"
            onClick={() => Navigate("/stories")}>
            Back
          </button>
        </div>
      </form>

      {/* <div
        className="table-responsive rounded shadow my-3"
        style={{ minHeight: "60vh", overflowY: "auto" }}>
        <table className="table table-hover table-bordered">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Title</th> */}
      {/* <th>Category</th> */}
      {/* <th>Description</th> */}
      {/* <th>Image</th>
              <th>Language</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {stories.map((story, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{story.title}</td> */}
      {/* <td>{story.category}</td> */}
      {/* <td>{story.description}</td> */}
      {/* <td>
                  {story.imageLink && (
                    <img
                      src={story.imageLink}
                      alt={story.title}
                      style={{ width: "100px", height: "auto" }}
                    />
                  )}
                </td>
                <td>
                  {story.languageId == 1
                    ? "English"
                    : "" || story.languageId == 2
                    ? "Hindi"
                    : "" || story.languageId == 3
                    ? "Telugu"
                    : ""}
                </td>
                <td>
                  <button
                    className="btn btn-outline-warning m-1"
                    onClick={(e) => {
                      e.stopPropagation();
                      gotoeditstory(story);
                    }}>
                    <i className="bi bi-pencil-square"></i>
                  </button>
                  <button className="btn btn-outline-danger m-1">
                    <i
                      className="bi bi-trash"
                      onClick={(e) => {
                        e.stopPropagation();
                        gotodeletestory(story);
                      }}></i>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div> */}
    </div>
  );
};

export default Addstory;
