import React, { useState, useEffect } from "react";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import { useNavigate } from "react-router-dom";
import { FadeLoader } from "react-spinners";
// import { SelectionState } from "react-draft-wysiwyg";
import { BASE_URL } from "../../config";

const Stories = () => {
  const [stories, setStories] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(200);
  const [totalStories, setTotalStories] = useState(0);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredcategories, setFilteredCategories] = useState([]);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [noDataMessage, setNoDataMessage] = useState("");
  // State variables for filters
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedLanguage, setSelectedLanguage] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState(0);
  const [originalPosts, setOriginalPosts] = useState([]);

  useEffect(() => {
    fetchStories(
      currentPage,
      selectedLanguage,
      selectedCategory,
      selectedStatus
    );
    categorydata();
  }, [currentPage, pageSize]);

  const categorydata = async () => {
    try {
      const response = await fetch(`${BASE_URL}/mm/getcategory`);
      const apiResponse = await response.json();
      const formattedCategories = apiResponse.data.map((category) => ({
        categoryId: category.categoryId,
        categoryName: category.categoryName || "N/A",
        categoryUrl: category.categoryUrl || "N/A",
        categoryCode: category.categoryCode || "N/A",
        language: category.language || "N/A",
        categoryType: category.categoryType || "N/A",
        categoryQuery: category.categoryQuery || "N/A",
        homeViewType: category.homeViewType || "N/A",
        categoryGroupId: category.categoryGroupId || "N/A",
        status: category.status,
      }));
      setCategories(formattedCategories);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const fetchStories = async (
    page,
    selectedLanguage,
    selectedCategory,
    selectedStatus
  ) => {
    console.log(page, selectedLanguage, selectedCategory, selectedStatus);
    try {
      const queryParams = new URLSearchParams({
        page: page + 1, // Backend expects page numbers starting from 1
        size: pageSize,
        // categoryId: parseInt(selectedCategory, 10),
        // language: parseInt(selectedLanguage, 10),
        // status: parseInt(selectedStatus, 10),
        contentType: 3,
      });

      if (parseInt(selectedCategory, 10) !== 0) {
        queryParams.append("categoryId", parseInt(selectedCategory, 10));
      }

      if (parseInt(selectedLanguage, 10) !== 0) {
        queryParams.append("language", parseInt(selectedLanguage, 10));
      }

      if (parseInt(selectedStatus, 10) !== 0) {
        queryParams.append("status", parseInt(selectedStatus, 10));
      }

      // Make the API request
      const response = await fetch(
        `${BASE_URL}/mm/getarticlesdata?${queryParams}`
      );
      if (!response.ok) {
        console.error(`Error: Received status ${response.status}`);
        setStories([]);
        setNoDataMessage("No data found for the applied filters");
        return;
      }

      // Parse the response JSON
      const apiResponse = await response.json();
      console.log("API Response:", apiResponse);

      // Handle "no data" scenario
      if (apiResponse.data === "no data found") {
        setStories([]);
        setNoDataMessage("No data found for the applied filters");
        return;
      }
      setLoading(true);
      setOriginalPosts(apiResponse?.data);
      setStories(apiResponse?.data); // Assuming apiResponse.data contains the list of posts
      setTotalStories(apiResponse?.data[0]?.totalCount);
    } catch (error) {
      console.error("Error fetching stories:", error);
    }
  };

  const navigate = useNavigate();

  const [filterdata, setFiltereddata] = useState("");

  const Searchfunction = (e) => {
    const value = e.target.value.toLowerCase();
    setNoDataMessage("");
    setFiltereddata(value);
    try {
      if (value !== "") {
        const filtered = filtermethod(value);
        if (filtered.length === 0) {
          setNoDataMessage("No matching posts found.");
        }
        setStories(filtered);
      } else {
        setStories(originalPosts);
      }
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };

  const filtermethod = (value) => {
    return originalPosts.filter((item) =>
      item.postTitle.toLowerCase().includes(value)
    );
  };

  const returnCategoryType = (category) => {
    switch (category.contentType) {
      case 1:
        return "Post";
      case 2:
        return "Videos";
      case 3:
        return "Photo Stories";
      default:
        return "Post";
    }
  };

  const returnLanguage = (language) => {
    switch (language) {
      case 1:
        return "English";
      case 2:
        return "Hindi";
      case 3:
        return "Telugu";
      default:
        return "English";
    }
  };

  const convertToCSV = (videos) => {
    const header = [
      "Category ID",
      "Category Name",
      "Category URL",
      "Language",
      "Category Type",
      "Post Title",
      "Post ID",
      "Post Url",
      "Created At",
      "Published Date",
      "Edited On",
      "Status",
      "Uploaded By",
    ];

    const escapeField = (field) => {
      if (field == null) return ""; // Handle null or undefined
      return `"${String(field).replace(/"/g, '""')}"`; // Escape double quotes and wrap in quotes
    };

    const rows = stories.map((category) => [
      escapeField(category.categoryId),
      escapeField(category.categoryName),
      escapeField(category.categoryUrl),
      escapeField(returnLanguage(category.language)),
      escapeField(returnCategoryType(category)),
      escapeField(category.postTitle),
      escapeField(category.postId),
      escapeField(category.postUrl),
      escapeField(category.createdAt),
      escapeField(category.publishedDate),
      escapeField(category.updatedAt || "N/A"), // Ensure editedOn is handled properly
      escapeField(category.status === 1 ? "Publish" : "Pending"),
      escapeField(category.uploadedBy),
    ]);

    // Join headers and rows with commas and new lines
    const csvContent = [header, ...rows].map((row) => row.join(",")).join("\n");
    return csvContent;
  };

  // Function to trigger CSV download
  const downloadCSV = () => {
    if (!stories || stories.length === 0) {
      alert("No data available to download.");
      return;
    }

    const csvData = convertToCSV(stories);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "stories.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      selectedCategory !== 0 ||
      selectedLanguage !== 0 ||
      selectedStatus !== 0
    ) {
      fetchStories(
        currentPage,
        selectedLanguage,
        selectedCategory,
        selectedStatus
      );
      //  console.log(selectedCategory,selectedLanguage,selectedStatus)
    } else {
      setStories(originalPosts);
    }
  };

  useEffect(() => {
    // Fetch categories when the component mounts
    categorydata();
  }, []);

  useEffect(() => {
    // Filter categories based on selected language
    if (selectedLanguage) {
      const filtered = categories.filter(
        (category) => category.language == selectedLanguage
      );
      setFilteredCategories(filtered);
    } else {
      setFilteredCategories(categories); // Reset if no language is selected
    }
  }, [selectedLanguage, categories]);

  const languagemethod = (e) => {
    setSelectedLanguage(e.target.value);
  };

  const handleEditStories = async (item) => {
    navigate("/editstory", { state: { item, categories } });
  };
  const handleaddstory = (item) => {
    navigate("/addstory", { state: { item } });
  };

  const redirectToAdds = () => {
    navigate("/addstory", { state: { categories } });
  };

  const handleDeletestories = (item) => {
    // const confirmDelete = window.confirm("Are you sure you want to delete this post?");
    // if (confirmDelete) {
    const postId = item.postId; // Assuming `item` has an `id` property

    console.log(postId);
    fetch(`${BASE_URL}/mm/delete?type=article&id=${postId}`, {
      method: "POST",
    })
      .then((response) => {
        if (response.ok) {
          setAlert({ show: true, message: "Delete Success", type: "success" });
          setTimeout(() => {
            setAlert({ show: false, message: "", type: "" });
            navigate("/stories");
            fetchStories(
              currentPage,
              selectedLanguage,
              selectedCategory,
              selectedStatus
            );
          }, 1000);
        } else {
          alert("Failed to delete story");
        }
      })
      .catch((error) => {
        console.error("Error deleting story:", error);
        setAlert({
          show: true,
          message: "Error deleting story. Please try again.",
          type: "danger",
        });
      });
  };

  useEffect(() => {
    //if all filters are reset, fetch posts again
    console.log(selectedCategory, selectedLanguage, selectedStatus);
    if (
      selectedCategory === "0" &&
      selectedLanguage === "0" &&
      selectedStatus === "0"
    ) {
      console.log("fetching posts again");
      setCurrentPage(0);
      fetchStories(
        currentPage,
        selectedLanguage,
        selectedCategory,
        selectedStatus
      );
    }
  }, [selectedCategory, selectedLanguage, selectedStatus]);

  const handleAlertClose = () => {
    setAlert({ show: false, message: "", type: "" });
    navigate("/stories");
  };
  const displaymethod = (item) => {
    window.location.href = `https://www.mobilemasala.com/${item.categoryUrl}/${item.postUrl}`;
  };
  console.log(filteredcategories);
  return (
    <section className="container-fluid m-auto p-auto">
      <div className="row">
        <div className="d-flex justify-content-between flex-wrap mt-5 px-4">
          <h3 className="fs-4">Photo Stories</h3>
          <button
            className="btn btn-outline-info shadow"
            onClick={redirectToAdds}>
            Add Stories
          </button>
        </div>
        {alert.show && (
          <div
            className={`alert alert-${alert.type} alert-dismissible w-50 fade show m-auto p-auto`}
            role="alert">
            {alert.message}
            <button
              type="button"
              className="btn-close"
              onClick={handleAlertClose}></button>
          </div>
        )}
        <div className="content-page">
          <div className="content">
            <div className="container-fluid">
              <div className="page-title-box pt-2"></div>
              <div className="row">
                <div className="col-12">
                  {loading ? (
                    <>
                      <div className="card shadow  custom-shadow">
                        <div className="card-body">
                          <form
                            onSubmit={handleSubmit}
                            className="row shadow border rounded p-2 m-1">
                            <div className="form-group col-12 col-md d-flex align-items-center m-1">
                              <label className="form-label mb-0 me-2 fw-bold">
                                Language:{" "}
                              </label>
                              <select
                                id="inputState2"
                                className="form-control"
                                value={selectedLanguage}
                                onChange={languagemethod}>
                                <option value={0}>-select-</option>
                                <option value={1}>English</option>
                                <option value={2}>Hindi</option>
                                <option value={3}>Telugu</option>
                              </select>
                            </div>
                            <div className="form-group col-12 col-md d-flex align-items-center m-1">
                              <label className="form-label mb-0 me-2 fw-bold">
                                Categories:{" "}
                              </label>
                              <select
                                id="inputState1"
                                className="form-control"
                                value={selectedCategory}
                                onChange={(e) =>
                                  setSelectedCategory(e.target.value)
                                }>
                                <option value={0}>-select-</option>

                                {filteredcategories.map((item) => (
                                  <option
                                    key={item.categoryId}
                                    value={item.categoryId}>
                                    {item.categoryName}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="form-group col-12 col-md d-flex align-items-center m-1">
                              <label className="form-label mb-0 me-2 fw-bold">
                                Status:{" "}
                              </label>
                              <select
                                id="inputState3"
                                className="form-control"
                                value={selectedStatus}
                                onChange={(e) =>
                                  setSelectedStatus(e.target.value)
                                }>
                                <option value={0}>-select-</option>

                                <option value={1}>Publish</option>
                                <option value={2}>Pending</option>
                              </select>
                            </div>
                            <div className="col-12 col-md d-flex align-items-center m-1">
                              <button className="btn btn-outline-warning text-dark w-auto fw-bold px-md-4">
                                Submit
                              </button>
                            </div>
                          </form>
                          <div className="row m-3">
                            <div className="col-12 d-flex flex-wrap justify-content-between">
                              <div className="btn btn-outline-success m-auto ">
                                <i
                                  className="fas fa-file-csv fs-sm-6"
                                  onClick={downloadCSV}>
                                  Download csv
                                </i>
                              </div>
                              <div className="form-group m-auto d-flex align-content-center">
                                <label className="form-label m-2 fw-bold">
                                  Search:
                                </label>
                                <input
                                  type="text"
                                  placeholder="Search..."
                                  className="form-control"
                                  onChange={Searchfunction}
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            className="table-responsive rounded "
                            style={{ maxHeight: "60vh", overflowY: "auto" }}>
                            <table className="table table-hover table-bordered shadow p-2">
                              <thead className="text-start">
                                <tr>
                                  <th>S.No</th>
                                  <th>CategoryName</th>
                                  <th>language</th>
                                  <th>PostTitle</th>
                                  <th>Status</th>
                                  <th>Received</th>
                                  <th>Published</th>
                                  <th>Edited</th>

                                  {/* <th>AddStory</th> */}

                                  <th>View</th>
                                  <th>Action</th>
                                  <th>UploadedBy</th>
                                </tr>
                              </thead>
                              <tbody className="text-start">
                                {stories.length > 0 ? (
                                  stories.map((item, index) => (
                                    <tr key={index}>
                                      <td>
                                        {index + 1 + currentPage * pageSize}
                                      </td>
                                      <td>{item.categoryName}</td>
                                      <td>
                                        {item.language === 1
                                          ? "English"
                                          : item.language === 2
                                          ? "Hindi"
                                          : item.language === 3
                                          ? "Telugu"
                                          : ""}
                                      </td>
                                      <td>{item.postTitle}</td>
                                      <td>
                                        <span
                                          className={
                                            item.status === 1
                                              ? "bg-success text-white rounded px-2 py-1"
                                              : "bg-danger text-white px-2 py-1 rounded"
                                          }>
                                          {item.status === 1
                                            ? "Publish"
                                            : "Pending"}
                                        </span>
                                      </td>

                                      <td>{item.createdAt}</td>
                                      <td>{item.publishedDate}</td>
                                      <td>{item.publishedDate}</td>
                                      {/* <td className="items-center">
                                        {item.categoryName == 12 ||
                                        item.categoryName == 30 ? (
                                          <button
                                            className="btn btn-outline-success btn-sm m-1 "
                                            onClick={(e) => {
                                              e.stopPropagation(); // Prevent row click event
                                              handleaddstory(item);
                                            }}>
                                            <i className="bi bi-image  text-dark"></i>
                                          </button>
                                        ) : (
                                          ""
                                        )}
                                      </td> */}

                                      <td>
                                        <button
                                          className="btn btn-outline-info btn-sm m-1"
                                          onClick={(e) => {
                                            e.stopPropagation(); // Prevent row click event
                                            displaymethod(item);
                                          }}>
                                          <i className="bi bi-eye fw-bold m-1 "></i>
                                        </button>
                                      </td>
                                      <td className="d-flex">
                                        <button
                                          className="btn btn-outline-secondary btn-sm m-1"
                                          onClick={(e) => {
                                            e.stopPropagation(); // Prevent row click event
                                            handleEditStories(item);
                                          }}>
                                          <i className="bi bi-pencil-square m-1 text-dark"></i>
                                        </button>
                                        <button
                                          className="btn btn-outline-danger btn-sm m-1"
                                          onClick={(e) => {
                                            e.stopPropagation(); // Prevent row click event
                                            handleDeletestories(item);
                                          }}>
                                          <i className="bi bi-trash m-1 text-danger ">
                                            {" "}
                                          </i>
                                        </button>
                                      </td>
                                      <td>{item.uploadedBy}</td>
                                    </tr>
                                  ))
                                ) : (
                                  // Display this row when no videos are available
                                  <tr>
                                    <td colSpan="11" className="text-center">
                                      No photo stories available
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                          <div className="row">
                            <div className="col">
                              <div className="d-flex justify-content-center align-content-center m-2">
                                <button
                                  disabled={currentPage === 0}
                                  onClick={() =>
                                    setCurrentPage((prevPage) => prevPage - 1)
                                  }
                                  className="btn btn-outline-info px-3">
                                  Prev
                                </button>
                                <span className="m-1 px-2">
                                  {currentPage + 1}
                                </span>
                                <button
                                  disabled={
                                    currentPage >=
                                    Math.ceil(totalStories / pageSize) - 1
                                  }
                                  onClick={() =>
                                    setCurrentPage((prevPage) => prevPage + 1)
                                  }
                                  className="btn btn-outline-info  px-3">
                                  Next
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <center className="my-4">
                      <FadeLoader color="#102154" />
                    </center>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Stories;
