

// import React, { createContext, useState, useEffect, useContext } from 'react';

// const AuthContext = createContext();

// export const useAuth = () => {
//   return useContext(AuthContext);
// };

// export const AuthProvider = ({ children }) => {
//   const [isLoggedIn, setIsLoggedIn] = useState(
//     localStorage.getItem('isLoggedIn') === 'false'
//   );

//     const [userInfo, setUserInfo] = useState({ username: '', email: '' });


//     useEffect(() => {
//       localStorage.setItem('isLoggedIn', isLoggedIn);
//       if (isLoggedIn) {
//         localStorage.setItem('userInfo', JSON.stringify(userInfo));
//       } else {
//         localStorage.removeItem('userInfo');
//       }
//     }, [isLoggedIn, userInfo]);
//   const login = (username,email) => {
//     setIsLoggedIn(true);
//     setUserInfo({ username, email });

//   };

//   const logout = () => {
//     setIsLoggedIn(false);
  
//     setUserInfo({ username: '', email: '' });


//   };

 
//   return (
//     <AuthContext.Provider value={{ isLoggedIn, login, logout, userInfo }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

import React, { createContext, useState, useEffect, useContext } from 'react';

const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('isLoggedIn') === 'true');
    
    // Initialize userInfo from localStorage if available
    const storedUserInfo = localStorage.getItem('userInfo');
    const [userInfo, setUserInfo] = useState(storedUserInfo ? JSON.parse(storedUserInfo) : { username: '', email: '' });

    useEffect(() => {
        localStorage.setItem('isLoggedIn', isLoggedIn);
        if (isLoggedIn) {
            localStorage.setItem('userInfo', JSON.stringify(userInfo));
        } else {
            localStorage.removeItem('userInfo');
        }
    }, [isLoggedIn, userInfo]);

    const login = (email, username) => {
        setIsLoggedIn(true);
        setUserInfo({ username, email });
    };

    const logout = () => {
        setIsLoggedIn(false);
        setUserInfo({ username: '', email: '' });
        localStorage.removeItem('userInfo'); // Clear userInfo from localStorage
    };

    return (
        <AuthContext.Provider value={{ isLoggedIn, login, logout, userInfo }}>
            {children}
        </AuthContext.Provider>
    );
};


