import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../config";

const Editstory = () => {
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null); // State for image preview
  const [storyTitle, setStoryTitle] = useState("");
  const [storyDescription, setStoryDescription] = useState("");
  const [language, setLanguage] = useState("");
  const [status, setStatus] = useState("");
  const location = useLocation();
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const Navigate = useNavigate();

  const { item } = location.state || {};

  useEffect(() => {
    if (item) {
      setStoryTitle(item.title || "");
      setStoryDescription(item.description || "");
      setLanguage(item.languageId || "");
      setStatus(item.status || "");
      setImage(item.imageLink || null);
      setImagePreview(item.imageLink || null); // Set image preview from item if available
    }
  }, [item]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImage(file);

    // Create a preview URL for the selected image
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setImagePreview(previewUrl);
    } else {
      setImagePreview(null); // Clear preview if no file is selected
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    const data = {
      title: storyTitle,
      description: storyDescription,
      languageId: language,
      status: status,
      postId: item.postId,
      imageId: item.imageId,
    };

    formData.append("data", JSON.stringify(data));
    formData.append("image", image);

    try {
      const response = await fetch(`${BASE_URL}/mm/saveOrUpdatevisual`, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      setAlert({ show: true, message: result.message, type: "success" });

      // Clear the form fields after successful submission
      setLanguage("");
      setStatus("");
      setStoryDescription("");
      setStoryTitle("");
      setImagePreview(null); // Clear image preview

      setTimeout(() => {
        setAlert({ show: false, message: "", type: "" });
        Navigate("/stories", { state: { item } }); // Include edited data here
      }, 1000);
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while updating the story.");
    }
  };

  const handleAlertClose = () => {
    setAlert({ show: false, message: "", type: "" });
    Navigate("/stories");
  };

  const postredirect = () => {
    Navigate("/stories");
  };

  return (
    <div className="container-fluid p-auto m-auto">
      <div className="d-flex justify-content-between mt-3">
        <h4 className="fs-4">Edit Story</h4>
        <button
          className="btn btn-outline-warning text-dark shadow"
          onClick={postredirect}>
          {" "}
          Stories
        </button>
      </div>
      {alert.show && (
        <div
          className={`alert alert-${alert.type} alert-dismissible w-50 fade show m-auto p-auto`}
          role="alert">
          {alert.message}
          <button
            type="button"
            className="btn-close"
            onClick={handleAlertClose}></button>
        </div>
      )}
      <form onSubmit={handleSubmit} className="card shadow mt-3">
        <div className="row m-3">
          <div className="col-12 col-md-6">
            <label className="form-label">Story Title *</label>
            <input
              type="text"
              className="form-control"
              value={storyTitle}
              onChange={(e) => setStoryTitle(e.target.value)}
            />
          </div>
          <div className="col-12 col-md-3">
            <label className="form-label">Choose Images *</label>
            <input
              type="file"
              className="form-control"
              accept="image/*"
              onChange={handleImageChange}
            />
            <small className="text-danger">
              Please Upload 720 X 1280 in Pixels Only
            </small>
          </div>
          <div className="col-12 col-md-3">
            {imagePreview && (
              <img
                src={imagePreview}
                alt="Preview"
                className="img-fluid mt-3"
                style={{ maxHeight: "100px", objectFit: "contain" }} // Optional styles for better display
              />
            )}
          </div>
        </div>

        <div className="row m-3">
          <div className="col-12 col-md-6">
            <label className="form-label">Select Language</label>
            <select
              className="form-select"
              value={language}
              onChange={(e) => setLanguage(e.target.value)}>
              <option value="">Select Language</option>
              <option value={1}>English</option>
              <option value={2}>Hindi</option>
              <option value={3}>Telugu</option>
            </select>
          </div>

          <div className="col-12 col-md-6">
            <label className="form-label">Status</label>
            <select
              className="form-select"
              value={status}
              onChange={(e) => setStatus(e.target.value)}>
              <option value="">--select--</option>
              <option value={1}>Publish</option>
              <option value={0}>Pending</option>
            </select>
          </div>
        </div>

        <div className="row m-3">
          <div className="col-12">
            <label className="form-label">Story Description</label>
            <textarea
              className="form-control"
              rows="3"
              value={storyDescription}
              onChange={(e) => setStoryDescription(e.target.value)}
            />
          </div>
        </div>

        <div className="d-flex justify-content-center m-3">
          <button type="submit" className="btn btn-primary mx-1">
            Save
          </button>
          <button
            type="button"
            className="btn btn-secondary mx-1"
            onClick={() => Navigate("/addstory")}>
            Back
          </button>
        </div>
      </form>
    </div>
  );
};

export default Editstory;
