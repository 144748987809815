import React from "react";
import { redirect, useNavigate } from "react-router-dom";

const NotFound = () => {
  const Navigate = useNavigate();
  const redirect = () => {
    Navigate("/");
  };

  return (
    <div className="row">
      <div className="col-12 p-md-5">
        <div className="d-flex  flex-column justify-content-center align-items-center p-md-5">
          <img src="/404.webp" alt="404-not-found" />

          <button className="btn btn-outline-info " onClick={redirect}>
            Redirect to Main Page
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotFound;